import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Box, Image, Switch, Badge } from '@mantine/core';
import { Button } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";


import Loading from '../../../../components/Loading/Loading'; 
import TableLoadingSkeleton from '../../../../components/TableSkelton';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Delete 
import Delete from './MechanicDelete';  

// API-Service
import { mechanicWithdrawalListingService, mechanicWithdrawalUpdateStatusService, mechanicWithdrawalSearchService, downloadMechFile, } from '../../../../service/withdrawalService';
  
// Redux-Settings 
import { 
  setMechanicWithdrawalsList,
  setMechanicTotalEntry,
  selectStateMechanicWithdrawals,
  selectMechanicTotalEntry,
} from '../../../../redux/withdrawalSlice';

// Auth-Image
import AuthImage from '../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../assets/images/placeholderThumb.png';

  
const ListingTable = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const Listing = useSelector(selectFilteredList);
  const Listing = useSelector(selectStateMechanicWithdrawals);
  const totalEntry = useSelector(selectMechanicTotalEntry);
  const [currentPage, setCurrentPage] = useState(0);
 

  const token = getToken();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const countPerPage = 10;

  const fetchData = async (page) => {
    const result = await mechanicWithdrawalListingService({page, countPerPage, token });
    if (result?.data?.status) {
      setLoading(false);
      dispatch(setMechanicWithdrawalsList(result?.data?.data?.withdraw_lists));
      dispatch(setMechanicTotalEntry(result?.data?.data?.pagination?.total));
    } else {
      setLoading(false);
      ErrorToast(result);
    }
  };

  const statusUpdate = async (rowRecord) => {
    const statusUpdateresult = await mechanicWithdrawalUpdateStatusService({ id: rowRecord.id, token });
    if (statusUpdateresult?.data?.status) {
      SuccessToast('Status changed successfully')
      fetchData(pageCount);
    } else {
      ErrorToast(statusUpdateresult);
    }
  };


  useEffect(() => {
    setLoading(true);
    fetchData(pageCount);
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [pageCount]);

  // ** Function to handle Pagination
  const handlePagination = page => {
    setCurrentPage(page.selected)
}


  // ** Custom Pagination
  const CustomPagination = () => (
    <ReactPaginate
        nextLabel={<div className='d-inline-flex align-items-center bg-white p-50 border border-2 rounded p-1'>
            <span className='fs-14 fw-bold me-2 text-dark '>Next</span>
            <AiOutlineArrowRight className='text-secondary' size={18} />
        </div>}
        breakLabel='...'
        previousLabel={<div className='d-inline-flex align-items-center bg-white p-50  border border-2 rounded p-1'>
            <AiOutlineArrowLeft className='text-secondary me-2' size={18} />
            <span className='fs-14 fw-bold text-dark' >Previous</span>
        </div>}
        pageRangeDisplayed={3}
        forcePage={currentPage}
        marginPagesDisplayed={3}
        activeClassName='active bg-light border rounded px-2 d-flex align-items-center text-dark border-3'
        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
        breakClassName='page-item me-3 d-flex align-items-center'
        nextClassName='page-item next-item flex-grow-1 text-end'
        previousClassName='page-item prev-item flex-grow-1 me-3'
        disabledLinkClassName='btn disabled p-0 border-0'
        pageCount={Math.ceil(totalEntry / 10) || 1}
        onPageChange={page => handlePagination(page)}
        containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-2 ps-1'
    />
)


  const columns = [
    {
      name: 'Name',
      selector: (row) => row.mechanic?.legal_name ? row.mechanic?.legal_name : row?.mechanic?.company_name,
      minWidth: '85px',
      wrap: true
    },
    {
      name: 'Email',
      selector: (row) => row.mechanic?.email ? row.mechanic?.email : '--',
      wrap: 'true',
      minWidth: '135px'
    },
    {
      name: 'Phone',
      selector: (row) => row.mechanic?.phone ? row.mechanic?.phone : '--',
      minWidth: '110px'
    },
    {
      name: 'Bank name',
      selector: (row) => row?.bank_name ? row?.bank_name : '--',
      minWidth: '92px',
      wrap: true
    },
   
    {
      name: 'Account Number',
      selector: (row) => row?.store_bank?.account_number ? row?.store_bank?.account_number : '--',
      minWidth: '130px',
      wrap: true
    },
    {
      name: 'Amount',
      selector: (row) => row.amount ? `RM ${row.amount.toFixed(2)}` : '--',
      minWidth: '49px'
    },
    
    {
      name: 'Status',
      selector: (row) =>
      {
        let showData = ''
        if (row.is_withdrawn === 0) {
          showData = <Badge size={14} color="red">PENDING</Badge>
        }
        else if (row.is_withdrawn === 1) {
          showData = <Badge size={14} color="green">Approved</Badge>
        }
        else if (row.is_withdrawn === 2) {
          showData = <Badge size={14} color="red">Rejected</Badge>
        } 
        return (showData)
      },
      minWidth: '115px'
    },
    {
      name: 'Payment Status',
      selector: (row) => {
        let paymentStatus = row?.payment_status ? row.payment_status : '--';
        if (paymentStatus === 'NOTPROCESSED') {
          paymentStatus = 'NOT-PROCESSED';
        }
        return paymentStatus;
      },
      minWidth: '130px'
    },
  

    // { 
    //   name: 'Status',
    //   selector: (row) => (
    //     <Switch
    //       checked={row.status === 1}
    //       onChange={() => statusUpdate(row)}
    //       onLabel="Active"
    //       offLabel="In Active"
    //       size="md"
    //       color="yellow"
    //     />  
    //   ), 
    //   maxWidth: '150px',
    // },
    {
      name: 'Created Date',
      selector: (row) => moment(row.created_at).format("DD-MM-YYYY"),
      minWidth: '100px', 
    },
    { 
      name: 'Actions',
      selector: (row) => <Delete tableRecord={row} />,
      sortable: true
    },
  ];

  

  return (
    <Box  sx={() => ({
      minHeight: '100vh',
      '.rdt_TableCol_Sortable div:first-of-type': {
        whiteSpace: 'initial',
        textOverflow: 'initial',
        overflow: 'visible'
      },
      '.rdt_TableCell div:first-of-type': {
        whiteSpace: 'initial',
        padding: '5px 0'
      },
      '@media (max-width: 820px)': {
        overflow: 'scroll',
      },
    })}
  >
      <Box className="rui-filemanager-content">
        <Box className="table-responsive-lg mb-20 inner-content-fix">
          {loading ? <TableLoadingSkeleton/> : (
          <DataTable
            className="rui-datatable rui-filemanager-table table mb-20 mt-10"
            columns={columns}
            data={Listing}
            key={Listing?.id}
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationTotalRows={totalEntry}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{ noRowsPerPage: true }}
            onChangePage={(page) => setPageCount(page)}
            paginationComponent={CustomPagination}
            paginationDefaultPage={currentPage + 1} 
          />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ListingTable;
