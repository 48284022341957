import React, { useState, useEffect } from 'react';
import { Grid, Paper, Group, Text, ThemeIcon, Skeleton } from '@mantine/core';
import { IconArrowUpRight } from '@tabler/icons';
import { useNavigate } from 'react-router-dom'

// API-Service   
import { shortStatsService } from '../../../../service/dashboardService';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

const Stats = () => {
  const [loading, setLoading] = useState(true);
  const token = getToken();
  const [stats, setStats] = useState({});
  const navigate = useNavigate()

  // Function to fetch stats data
  const fetchData = async () => {
    try {
      const result = await shortStatsService({ token });
      if (result?.data?.status) {
        setLoading(false);
        setStats(result?.data?.data); // Store the data in state
      } else {
        setLoading(false);
        ErrorToast(result);
      }
    } catch (error) {
      setLoading(false);
      ErrorToast(error);
    }
  };

  // Fetch the data on component mount
  useEffect(() => {
    fetchData();
  }, [token]); // Return if the token changes

  return (
    <Grid>
      {[
        { label: 'Total Users', value: stats?.totalCustomers, route: '/admin/users' },
        { label: 'Total Mechanics', value: stats?.totalMechanics, route: '/admin/mechanics' },
        { label: 'Total Freelancers', value: stats?.totalFreelancers, route: '/admin/mechanics' },
        { label: 'BreakDown Bookings', value: stats?.totalBreakdownBooking, route: '/admin/bookings' },
        { label: 'Service Bookings', value: stats?.totalServiceBooking, route: '/admin/service-bookings' },
        { label: 'BreakDown Revenue', value: stats?.totalRevenueBreakdown, route: '/admin/transactions' },
        { label: 'Service Revenue', value: stats?.totalRevenueService, route: '/admin/transactions' },
        { label: 'Pending Withdrawals', value: stats?.totalPendingWithdraws, route: '/admin/withdrawals' },
        { label: 'Total Complaints', value: stats?.totalReports, route: '/admin/user-complains' },
        { label: 'Contact Us', value: stats?.totalContactUsCount, route: '/admin/contacts' },
      ].map((stat) => (
        <Grid.Col key={stat.label} md={6} lg={6} sm={12}> {/* Use unique 'label' as key */}
          <Paper withBorder p="md" radius="md" style={{ height: '130px' }}>
            <Group position="apart">
              <div>
                <Text color="dimmed" transform="uppercase" weight={800} size="xl">
                  {stat.label}
                </Text>
                {/* Show skeleton loader only for the value when loading */}
                {loading ? (
                  <Skeleton height={40} width="50%" mt={10} />
                ) : (
                  <Text weight={800} size="xl">
                    {['BreakDown Revenue', 'Service Revenue', 'Pending Withdrawals'].includes(stat.label)
                      ? `RM ${stat.value ? stat.value.toFixed(2) : '0.00'}`
                      : stat.value || 0}
                  </Text>

                )}
              </div>
              <ThemeIcon
                color="gray"
                variant="light"
                sx={(theme) => ({ color: theme.colors.teal[6] })}
                size={38}
                onClick={() => navigate(stat.route)}
                style={{ cursor: 'pointer' }}
                radius="md"
              >
                <IconArrowUpRight size={28} stroke={1.5} />
              </ThemeIcon>
            </Group>
          </Paper>
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default Stats;
