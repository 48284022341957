import React from 'react';
import { Table, Skeleton } from '@mantine/core';

const TableLoadingSkeleton = () => (
  <Table>
    <tbody>
      <tr>
        <td colSpan={12}>
          <Skeleton height={40} style={{ marginBottom: '10px' }} />
          <Skeleton height={40} style={{ marginBottom: '10px' }} />
          <Skeleton height={40} style={{ marginBottom: '10px' }} />
          <Skeleton height={40} style={{ marginBottom: '10px' }} />
          <Skeleton height={40} style={{ marginBottom: '10px' }} />
          <Skeleton height={40} style={{ marginBottom: '10px' }} />
          <Skeleton height={40} style={{ marginBottom: '10px' }} />
          <Skeleton height={40} style={{ marginBottom: '10px' }} />
          <Skeleton height={40} style={{ marginBottom: '10px' }} />
          <Skeleton height={40} style={{ marginBottom: '10px' }} />
        </td>
      </tr>
    </tbody>
  </Table>
)

export default TableLoadingSkeleton
