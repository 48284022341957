import React, { useState, useEffect } from 'react';
import { Menu, Button, Image, TextInput, Modal, Group, Box, ActionIcon } from '@mantine/core';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FiEdit, FiTrash, FiMoreVertical } from 'react-icons/fi';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';
import FileUpload from './FileUploader';

// API-Service
import { userRemoveService, userUpdateService } from '../../../../service/user';

const DeleteUpdate = ({ tableRecord, fetchData }) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [file, setFile] = useState();
  const [showImage, setImage] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();


  // React Hook Form
  const { control, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    defaultValues: {
      name: tableRecord?.name || '',
      email: tableRecord?.email || '',
      phone: tableRecord?.phone || ''
    }
  });

  const hendleUpdateModal = () => {
    setIsUpdateModal(true);
  };

  const changeHandler = (event) => {
    setFile(event.target.files[0]);
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  useEffect(() => {
    setValue('name', tableRecord?.name || '');
    setValue('email', tableRecord?.email || '');
    setValue('phone', tableRecord?.phone || '');
    setImage(tableRecord?.profile_image)
  }, [tableRecord, setValue]);

  const hendleDeleteModal = () => {
    setIsDeleteModal(true);
  };

  const hendleCancel = () => {
    setIsDeleteModal(false);
    setIsUpdateModal(false);
    reset()
  };

  const hendleDelete = async () => {
    const result = await userRemoveService({
      id: tableRecord?.id,
      token,
    });
    setIsDeleteModal(false);
    if (result?.data?.status) {
      SuccessToast(result.data.message);
      fetchData();
    } else {
      ErrorToast(result.data.message);
    }
  };

  const handleUpdate = async (data) => {
    const formData = new FormData();
    if (file) {
      formData.append('profile_pic', file);
    }
    formData.append('name', data?.name);
    formData.append('email', data?.email);
    formData.append('phone', data?.phone);
    formData.append('_method', 'PUT');

    const result = await userUpdateService({ token, id: tableRecord.id }, formData);
    setIsUpdateModal(false)
    if (result?.data?.status) {
      SuccessToast(result.data.message);
      fetchData();
    } else {
      ErrorToast(result.data.message);
    }

  };

  return (
    <Box>
      <Menu control={<ActionIcon><FiMoreVertical size={18} /></ActionIcon>}>
        <Menu.Item onClick={hendleUpdateModal} icon={<FiEdit size={14} />}>
          Edit
        </Menu.Item>
        <Menu.Item onClick={hendleDeleteModal} icon={<FiTrash size={14} />}>
          Delete
        </Menu.Item>
      </Menu>

      <Modal opened={isDeleteModal} onClose={() => {
        setIsDeleteModal(false); // Close the modal
        reset(); // Reset the form or any other state
      }} title="Are you sure you want to delete?" centered>
        <Group position="center">
          <Button variant="outline" onClick={hendleCancel}>
            Cancel
          </Button>
          <Button color="yellow" onClick={hendleDelete}>
            Delete
          </Button>
        </Group>
      </Modal>

      <Modal opened={isUpdateModal} onClose={() => {
        setIsUpdateModal(false); // Close the modal
        reset(); 
      }} title="Update User" centered>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextInput {...field} label="Name" required error={errors.name} />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextInput {...field} label="Email" required error={errors.email} />
            )}
          />
          <Controller
            name="phone"
            rules={{
              required: 'This field is required',
              pattern: {
                value: /^\d{9,11}$/,
                message: 'Phone number must be between 9 to 11 digits'
              }
            }}
            control={control}
            render={({ field }) => (
              <TextInput {...field} label="Phone" required error={errors.phone && errors.phone.message} />
            )}
          />


          {showImage && <Image src={showImage} alt="Service Image" sx={{ height: 80, width: 80, overflow: 'hidden', marginTop: 15 }} />}

          <div style={{ marginTop: 15 }}>
            <TextInput label="Select Photo" type="file" accept="image/png, image/jpg, image/jpeg" onChange={changeHandler} />
          </div>

          <Group position="center" mt="md">
            <Button color="dark" onClick={hendleCancel}>
              Cancel
            </Button>
            <Button color="yellow" type="submit">
              Update
            </Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default DeleteUpdate;
