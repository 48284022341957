import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

export const dataListingService = ({ page, countPerPage, token, id }) => {
  // Construct the base URL
  let url = `${baseUrl}/admin/service-booking/list?paginate=1&page=${page}&perPage=${countPerPage}`;
  
  // Append the `mechanic_id` only if `id` is not null or undefined
  if (id != null) {
    url += `&mechanic_id=${id}`;
  }

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
}
   

  export const userSearchService = ({ search, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/service-booking/list?paginate=1&keyword=${search}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });

  export const bookingFilterService = ({ filterTag, countPerPage, token, page }) =>
   axios.get(`${baseUrl}/admin/service-booking/list?paginate=1&booking_current_status=${filterTag}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
   
  export const bookingDetail = ({ id, token }) =>
  axios.get(
    `${baseUrl}/admin/service-booking/single/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  export const cancelBookingService = ({ token }, formData) =>
  axios.post(
    `${baseUrl}/admin/service-booking/cancel-booking`, formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data'
        // 'application/json',
      },
    } 
  );



export const dataRemoveService = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/coupons/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
