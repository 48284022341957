import React, { useState, useEffect } from 'react';
import { Box, Image, Switch, Avatar, Table } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';


import Loading from '../../../../components/Loading/Loading'; 
import TableLoadingSkeleton from '../../../../components/TableSkelton';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Delete and Update
import DeleteUpdate from './DeleteUpdate';
  
// API-Service
import { mechanicListService, mechanicUpdateStatusService } from '../../../../service/mechanic.service';
  
// Redux-Settings
import { 
  setMechanicServiceList,
  selectMechanicServiceList,
  setTotalEntry,
  selectFilterTerm,
  selectTotalEntry,
  setFilteredList,
  selectFilteredList,
} from '../../../../redux/mechanicServiceSlice';

// Auth-Image
import AuthImage from '../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../assets/images/placeholderThumb.png';

  
const ListingTable = () => {
  const filterTag = useSelector(selectFilterTerm);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const Listing = useSelector(selectFilteredList);
  const [currentPage, setCurrentPage] = useState(0);
  const Listing = useSelector(selectMechanicServiceList);
  const totalEntry = useSelector(selectTotalEntry);

  const token = getToken();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const countPerPage = 10;

  const fetchData = async (filterTerm, page) => {
    setLoading(true)
    const result = await mechanicListService({ filterTerm, page, countPerPage, token });
    if (result?.data?.status) {
      setLoading(false);
      dispatch(setMechanicServiceList(result?.data?.data?.mechanic_services_list));
      dispatch(setFilteredList(result?.data?.data?.mechanic_services_list));
      dispatch(setTotalEntry(result?.data?.data?.pagination?.total));
    } else {
      setLoading(false);
      ErrorToast(result);
    }
  };

  const statusUpdate = async (rowRecord) => {
    const statusUpdateresult = await mechanicUpdateStatusService({ id: rowRecord.id, token });
    if (statusUpdateresult?.data?.status) {
       SuccessToast('Status changed successfully');
      fetchData(filterTag, pageCount);
    } else {
      ErrorToast(statusUpdateresult);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData(filterTag, pageCount);
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [filterTag, pageCount]);

   // ** Function to handle Pagination
   const handlePagination = page => {
    setCurrentPage(page.selected)
}

   // ** Custom Pagination
   const CustomPagination = () => (
    <ReactPaginate
        nextLabel={<div className='d-inline-flex align-items-center bg-white p-50 border border-2 rounded p-1'>
            <span className='fs-14 fw-bold me-2 text-dark '>Next</span>
            <AiOutlineArrowRight className='text-secondary' size={18} />
        </div>}
        breakLabel='...'
        previousLabel={<div className='d-inline-flex align-items-center bg-white p-50  border border-2 rounded p-1'>
            <AiOutlineArrowLeft className='text-secondary me-2' size={18} />
            <span className='fs-14 fw-bold text-dark' >Previous</span>
        </div>}
        pageRangeDisplayed={3}
        forcePage={currentPage}
        marginPagesDisplayed={3}
        activeClassName='active bg-light border rounded px-2 d-flex align-items-center text-dark border-3'
        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
        breakClassName='page-item me-3 d-flex align-items-center'
        nextClassName='page-item next-item flex-grow-1 text-end'
        previousClassName='page-item prev-item flex-grow-1 me-3'
        disabledLinkClassName='btn disabled p-0 border-0'
        pageCount={Math.ceil(totalEntry / 10) || 1}
        onPageChange={page => handlePagination(page)}
        containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-2 ps-1'
    />
)

  const columns = [
   
    {
      name: 'Service Image',
      selector: (row) => (
        (
          <Avatar
            radius='xl'
            size={60}
            src={row?.image || PlaceHolderThumb}
            alt="Profile"
            href={row?.image}
            target="_blank"
            component="a"
            style={{margin : '5px 0'}}
          />

        )),
    },
    {
      name: 'Selected Image',
      selector: (row) => (
        (
          <Avatar
            radius="xl"
            size={60}
            src={row?.selected_image  || PlaceHolderThumb}
            alt="Profile"
            href={row?.image}
            target="_blank"
            component="a"
          />

        )),
    },
    
    {
      name: 'Service Name',
      selector: (row) => row.name,
    },
    { 
      name: 'Status',
      selector: (row) => (
        <Switch
          checked={row.is_enabled}
          onChange={() => statusUpdate(row)}
          onLabel="Active"
          offLabel="Inactive"
          size="md"
          color="yellow"
        />
      ), 

    },
    {
      name: 'Created Date',
      selector: (row) => moment(row.created_at).format("DD-MM-YYYY"),
      maxWidth: '300px', 
    },
    { 
      name: 'Actions',
      selector: (row) => <DeleteUpdate tableRecord={row} />,
      sortable: true,
    },
  ];

  if ( loading) {
    return (
      <TableLoadingSkeleton/>
      
    );
  }

  return (
    <Box
      sx={() => ({
        minHeight: '100vh',
        '.rdt_TableCol_Sortable div:first-of-type':{
          whiteSpace: 'initial',
          textOverflow: 'initial',
          overflow: 'visible'
        },
        '.rdt_TableCell div:first-of-type':{
          whiteSpace: 'initial',
          padding: '5px 0'
        },
        '@media (max-width: 820px)': {
          overflow: 'scroll',
        },
      })}
    >
      <Box className="rui-filemanager-content">
        <Box className="table-responsive-lg mb-20 inner-content-fix">
          
          <DataTable
            className=""
            columns={columns}
            data={Listing} 
            key={Listing?.id}
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationTotalRows={totalEntry}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{ noRowsPerPage: true }}
            onChangePage={(page) => setPageCount(page)}
            paginationComponent={CustomPagination}
            paginationDefaultPage={currentPage + 1}
          />
          
        </Box>
      </Box>
    </Box>
  );
};

export default ListingTable;
