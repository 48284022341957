import React, { useState, useEffect, useCallback } from 'react';
import { TextInput, Text, FileButton, Button, Flex, Group, List, Image, Textarea, MultiSelect, Card, Grid, Calendar, Box, Modal, Notification } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiFileText, FiX } from "react-icons/fi";
import myUpload from 'vue-image-crop-upload';
import { FaFilePdf } from 'react-icons/fa';

// ** Third Party Components
import Select from 'react-select'

import Loading from '../../../../components/Loading/Loading';

// API-Service   
import { getAllListService, getSingleDataService, mechanicListService, mechanicUpdateService } from '../../../../service/mechanic';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast, CatchErrorToast, CustomErrorToast } from '../../../../utils/utilities';

// Redux-Settings
import {
    setMechanicList,
    setNewServices,
    setTotalEntry,
    selectStateSingleData,
    setSingleData,
    selectServicesList,
} from '../../../../redux/mechanicSlice';

const Update = ({ tableRecord }) => {
    const [startDate, setStartDate] = useState(new Date());

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showProfileImage, setProfileImage] = useState();
    const [services, setServices] = useState([]);
    const [bookingServices, setBookingServices] = useState([]);
    const token = getToken();
    const navigate = useNavigate();
    const [formValue, setFromValue] = useState('');
    const [inputError, setInputError] = useState(false);
    const { id } = useParams()
    const [files, setFiles] = useState([])
    const [ssmDocuments, setSsmDocuments] = useState([])
    const [drivingLicense, setDrivingLicense] = useState([])
    const [utilityBill, setUtilityBill] = useState([])
    const [qualificationCertificate, setQualificationCertificate] = useState([])
    const [liabilityInsurance, setLiabilityInsurance] = useState([])

    const form = useForm({
        initialValues: {
            // company_name email phone ssm ic_number bookingServices subscription_start_date subscription_end_date experience_summary
            company_name: '',
            legal_name: null,
            // email: '',
            phone: '',
            // ssm: '',
            ic_number: '',
            bookingServices: '',
            subscription_start_date: '',
            subscription_end_date: '',
            experience_summary: ''
        },
        // validate: {
        //     code: (value) => (value.length < 2 ? 'Please enter name' : null),
        //     couponType: (value) => (value.length < 2 ? 'Please enter type' : null),
        //     discount: (value) => (value.length < 2 ? 'Please enter discount' : null),
        //     max_discount: (value) => (value.length < 2 ? 'Please enter max mount' : null),
        //     min_purchase: (value) => (value.length < 2 ? 'Please enter min purchase amount' : null),
        //     limit_per_user: (value) => (value.length < 1 ? 'Please enter limit per user' : null),
        //     user_type: (value) => (value.length < 2 ? 'Please enter user type' : null),
        //     expire_coupon: (value) => (value.length < 2 ? 'Please select expire date' : null),
        // },
    });


    const fetchData = async () => {
        const result = await getAllListService({ token });
        if (result?.data?.status) {
            const tempValue = result?.data?.data?.mechanic_services_list?.map((item) => ({ value: item.id, label: item.name }));
            dispatch(setBookingServices(tempValue));
        }
    };

    const getSingleData = async () => {
        const result = await getSingleDataService({ id, token });
        if (result?.data?.status) {
            setLoading(false);
            setFromValue(result?.data?.data);
            if (result?.data?.data?.services.length > 0) {
                setServices(result?.data?.data?.services.map((item, key) => ({ value: item.id, label: item.name })))
            } else {
                console.log('error')
            }

            // For Ic File
            if (result?.data?.data?.ic_images.length > 0) {
                // Map the backend documents and store paths for display
                setFiles(result?.data?.data?.ic_images.map((item) => ({
                    path: item.path,
                    name: item.name,
                    type: item.path.endsWith('.pdf') ? 'pdf' : 'image', // Identify if the document is an image or PDF
                })));
            }

            // For SSM Image
            if (result?.data?.data?.ssm_documents.length > 0) {
                // Map the backend documents and store paths for display
                setSsmDocuments(result?.data?.data?.ssm_documents.map((item) => ({
                    path: item.path,
                    name: item.name,
                    type: item.path.endsWith('.pdf') ? 'pdf' : 'image', // Identify if the document is an image or PDF
                })));
            }

            // For Driving License
            if (result?.data?.data?.driving_license.length > 0) {
                // Map the backend documents and store paths for display
                setDrivingLicense(result?.data?.data?.driving_license.map((item) => ({
                    path: item.path,
                    name: item.name,
                    type: item.path.endsWith('.pdf') ? 'pdf' : 'image', // Identify if the document is an image or PDF
                })));
            }

            // For Utility Bill
            if (result?.data?.data?.utility_bills.length > 0) {
                // Map the backend documents and store paths for display
                setUtilityBill(result?.data?.data?.utility_bills.map((item) => ({
                    path: item.path,
                    name: item.name,
                    type: item.path.endsWith('.pdf') ? 'pdf' : 'image', // Identify if the document is an image or PDF
                })));
            }

            // For Qualification Certificate
            if (result?.data?.data?.qualification_certificate.length > 0) {
                // Map the backend documents and store paths for display
                setQualificationCertificate(result?.data?.data?.qualification_certificate.map((item) => ({
                    path: item.path,
                    name: item.name,
                    type: item.path.endsWith('.pdf') ? 'pdf' : 'image', // Identify if the document is an image or PDF
                })));
            }

            // For Liblity Insurance
            if (result?.data?.data?.liability_insurance.length > 0) {
                // Map the backend documents and store paths for display
                setLiabilityInsurance(result?.data?.data?.liability_insurance.map((item) => ({
                    path: item.path,
                    name: item.name,
                    type: item.path.endsWith('.pdf') ? 'pdf' : 'image', // Identify if the document is an image or PDF
                })));
            }

        } else {
            setLoading(false);
            ErrorToast(result);
        }
    }

    useEffect(() => {
        getSingleData();
        fetchData();
        // if (formValue?.ssm_documents) {
        //     setImages(formValue?.ssm_documents?.map((item) => (item.name)));
        // } else {
        //     setImages(formValue?.ic_images?.map((item) => (item.name)));
        // }

        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, []);

    const handleCompanyNameChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            company_name: e.target.value,
        }));
    };
    const handleLegalNameChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            legal_name: e.target.value,
        }));
    };
    const handleServicesChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            services: e,
        }));
    };

    // const handleEmailChange = (e) => {
    //     setInputError(false);
    //     setFromValue((prevState) => ({
    //         ...prevState,
    //         email: e.target.value,
    //     }));
    // };

    const handlePhoneChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            phone: e.target.value,
        }));
    };

    const handleSMChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            ssm: e.target.value,
        }));
    };

    const handleICChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            ic_number: e.target.value,
        }));
    };

    const handleDiscriptionChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            experience_summary: e.target.value,
        }));
    };

    const handleDateChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            subscription_start_date: e.target.value,
        }));
    };

    const handleSubDateChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            subscription_end_date: e.target.value,
        }));
    };

    const handleUpdate = async (e) => {
        // console.log('input', file)
        // e.preventDefault();

        if (files.length > 3) {
            CustomErrorToast("You can only upload a maximum of 3 files for IC images.");
            return; // Exit if the validation fails
          }
        
          if (ssmDocuments.length > 3) {
            CustomErrorToast("You can only upload a maximum of 3 files for SSM documents.");
            return;
          }
        
          if (drivingLicense.length > 3) {
            CustomErrorToast("You can only upload a maximum of 3 driving license files.");
            return;
          }
        
          if (utilityBill.length > 3) {
            CustomErrorToast("You can only upload a maximum of 3 utility bill files.");
            return;
          }
        
          if (qualificationCertificate.length > 3) {
            CustomErrorToast("You can only upload a maximum of 3 qualification certificate files.");
            return;
          }
        
          if (liabilityInsurance.length > 3) {
            CustomErrorToast("You can only upload a maximum of 3 liability insurance files.");
            return;
          }

        const formData = new FormData()
        if (showProfileImage) {
            console.log('Profile Image:', showProfileImage);
            formData.append('profile_pic', showProfileImage);
        }

        // ... (rest of the formData appending)

        // For Ic Image
        if (files.length) {
            files.forEach((fileObj, index) => {
                if (fileObj.path) {

                    // If it's a backend document (with path)
                    formData.append(`old_ic_images[${index}]`, fileObj.name);
                } else if (fileObj.file) {
                    // If it's a newly selected file (with file object)
                    formData.append(`ic_images[${index}]`, fileObj.file); // Use file.file for the actual file
                }
            });
        }

        // For SSM Image
        if (ssmDocuments.length) {
            ssmDocuments.forEach((fileObj, index) => {
                if (fileObj.path) {

                    // If it's a backend document (with path)
                    formData.append(`old_ssm_documents[${index}]`, fileObj.name);
                } else if (fileObj.file) {
                    // If it's a newly selected file (with file object)
                    formData.append(`ssm_documents[${index}]`, fileObj.file); // Use file.file for the actual file
                }
            });
        }

        // For Driving
        if (drivingLicense.length) {
            drivingLicense.forEach((fileObj, index) => {
                if (fileObj.path) {

                    // If it's a backend document (with path)
                    formData.append(`old_driving_license[${index}]`, fileObj.name);
                } else if (fileObj.file) {
                    // If it's a newly selected file (with file object)
                    formData.append(`driving_license[${index}]`, fileObj.file); // Use file.file for the actual file
                }
            });
        }

        // For Utlity Bill
        if (utilityBill.length) {
            utilityBill.forEach((fileObj, index) => {
                if (fileObj.path) {

                    // If it's a backend document (with path)
                    formData.append(`old_utility_bill[${index}]`, fileObj.name);
                } else if (fileObj.file) {
                    // If it's a newly selected file (with file object)
                    formData.append(`utility_bill[${index}]`, fileObj.file); // Use file.file for the actual file
                }
            });
        }

        // For Qualification Certificate Bill
        if (qualificationCertificate.length) {
            qualificationCertificate.forEach((fileObj, index) => {
                if (fileObj.path) {

                    // If it's a backend document (with path)
                    formData.append(`old_qualification_certificate[${index}]`, fileObj.name);
                } else if (fileObj.file) {
                    // If it's a newly selected file (with file object)
                    formData.append(`qualification_certificate[${index}]`, fileObj.file); // Use file.file for the actual file
                }
            });
        }

        // For Libality Bill
        if (liabilityInsurance.length) {
            liabilityInsurance.forEach((fileObj, index) => {
                if (fileObj.path) {
                    // If it's a backend document (with path)
                    formData.append(`old_liability_insurance[${index}]`, fileObj.name);
                } else if (fileObj.file) {
                    // If it's a newly selected file (with file object)
                    formData.append(`liability_insurance[${index}]`, fileObj.file); // Use file.file for the actual file
                }
            });
        }

        if (services.length) {
            services.map((element, index) => (
                formData.append(`service_ids[${index}]`, element.value)
            ))
        }

        if (formValue.mechanic_type === 'FREELANCER') {
            formData.append('legal_name', formValue?.legal_name)
            formData.append('ic_number', formValue?.ic_number)
            formData.append('experience_summary', formValue?.experience_summary)
        } else {
            formData.append('company_name', formValue?.company_name)
            formData.append('ssm', formValue?.ssm)
        }
        formData.append('id', formValue?.id)
        formData.append('start_date', formValue?.start_date)
        formData.append('end_date', formValue?.end_date)
        // formData.append('email', 'azad@segwitz.com')
        formData.append('phone', formValue?.phone)
        formData.append('subscription_start_date', formValue?.subscription_start_date)
        formData.append('subscription_end_date', formValue?.subscription_end_date)
        formData.append('_method', 'PUT')
        const result = await mechanicUpdateService({ token, id }, formData);

        if (result?.data?.status) {
            const resultAll = await mechanicListService({
                filterTerm: '',
                page: 1,
                countPerPage: 10,
                token
            })
                .catch((error) => {
                    console.log('MyError', error.response.data.message)
                    CatchErrorToast(error);
                });
            if (resultAll?.data?.status) {
                form.reset();
                dispatch(setTotalEntry(result?.data?.data?.pagination?.total));
                setLoading(false);
                SuccessToast(result?.data?.message)
                navigate('/admin/mechanics');
                form.reset();
            } else {
                setLoading(false);
                ErrorToast(resultAll);

            }
        }
    };

    const changeHandler = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            console.log('Selected File Type:', selectedFile.type);

            if (selectedFile.type.startsWith('image') && ['image/jpeg', 'image/png'].includes(selectedFile.type)) {
                setProfileImage(selectedFile);  // Update to store the File object
            } else {
                console.error('Invalid file type. Please select a valid image file (jpg, jpeg, png).');
                // Display an error message to the user
            }
        }
    };



    // const selectImages = (event) => {
    //     console.log('b', event.target.files)
    //     setFiles(event.target.files)
    // };


    const renderFilePreview = file => {
        if (file.type.startsWith('image')) {
            return <img className='rounded' alt={file.name} src={URL.createObjectURL(file)} height='28' width='28' />
        }
        return <FiFileText size='28' />
        // else {
        //     <FiFileText size='28' />
        // }
    }



    // Handle file selection
    const handleFileSelect = (event) => {
        const selected = Array.from(event.target.files); // Convert FileList to array
        const newFiles = selected.map((file) => ({
            file, // The actual file object
            type: file.type, // Type (image or pdf)
            preview: file.type === 'application/pdf' ? null : URL.createObjectURL(file), // Image preview if not a PDF
            name: file.name, // File name
        }));
        setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Append the newly selected files to the state
    };

    // Handle file deselection
    const handleDeselectFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove the file at the specific index
    };

    // Handle file selection For SSm
    const handleFileSelectForSSM = (event) => {
        const selected = Array.from(event.target.files); // Convert FileList to array
        const newFiles = selected.map((file) => ({
            file, // The actual file object
            type: file.type, // Type (image or pdf)
            preview: file.type === 'application/pdf' ? null : URL.createObjectURL(file), // Image preview if not a PDF
            name: file.name, // File name
        }));
        setSsmDocuments((prevFiles) => [...prevFiles, ...newFiles]); // Append the newly selected files to the state
    };

    // Handle file deselection for ssm
    const handleDeselectFileForSSM = (index) => {
        setSsmDocuments((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove the file at the specific index
    };

    // Handle file selection For Driving
    const handleFileSelectForDriving = (event) => {
        const selected = Array.from(event.target.files); // Convert FileList to array
        const newFiles = selected.map((file) => ({
            file, // The actual file object
            type: file.type, // Type (image or pdf)
            preview: file.type === 'application/pdf' ? null : URL.createObjectURL(file), // Image preview if not a PDF
            name: file.name, // File name
        }));
        setDrivingLicense((prevFiles) => [...prevFiles, ...newFiles]); // Append the newly selected files to the state
    };

    // Handle file deselection for Driving
    const handleDeselectFileForDriving = (index) => {
        setDrivingLicense((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove the file at the specific index
    };
 
    // Handle file selection For Utlity
    const handleFileSelectForUtlity = (event) => {
        const selected = Array.from(event.target.files); // Convert FileList to array
        const newFiles = selected.map((file) => ({
            file, // The actual file object
            type: file.type, // Type (image or pdf)
            preview: file.type === 'application/pdf' ? null : URL.createObjectURL(file), // Image preview if not a PDF
            name: file.name, // File name
        }));
        setUtilityBill((prevFiles) => [...prevFiles, ...newFiles]); // Append the newly selected files to the state
    };

    // Handle file deselection for Driving
    const handleDeselectFileForUtlity = (index) => {
        setUtilityBill((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove the file at the specific index
    };

    // Handle file selection For Certficate
    const handleFileSelectForCertificate = (event) => {
        const selected = Array.from(event.target.files); // Convert FileList to array
        const newFiles = selected.map((file) => ({
            file, // The actual file object
            type: file.type, // Type (image or pdf)
            preview: file.type === 'application/pdf' ? null : URL.createObjectURL(file), // Image preview if not a PDF
            name: file.name, // File name
        }));
        setQualificationCertificate((prevFiles) => [...prevFiles, ...newFiles]); // Append the newly selected files to the state
    };

    // Handle file deselection for Driving
    const handleDeselectFileForCertificte = (index) => {
        setQualificationCertificate((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove the file at the specific index
    };

     // Handle file selection For Certficate
     const handleFileSelectForLibality = (event) => {
        const selected = Array.from(event.target.files); // Convert FileList to array
        const newFiles = selected.map((file) => ({
            file, // The actual file object
            type: file.type, // Type (image or pdf)
            preview: file.type === 'application/pdf' ? null : URL.createObjectURL(file), // Image preview if not a PDF
            name: file.name, // File name
        }));
        setLiabilityInsurance((prevFiles) => [...prevFiles, ...newFiles]); // Append the newly selected files to the state
    };

    // Handle file deselection for Driving
    const handleDeselectFileForLibality = (index) => {
        setLiabilityInsurance((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove the file at the specific index
    };

   


    const handleCancel = () => {
        setInputError(false);
        form.reset();
        navigate('/admin/mechanics');
    };

    if (loading) {
        return (
            <Box>
                <Loading />
            </Box>
        );
    }



    return (
        <Box>
            <Card>
                <Grid>
                    <Grid.Col span={12}><h3>Update Profile</h3></Grid.Col>
                </Grid>
                <form onSubmit={form.onSubmit((values) => handleUpdate(values))}>
                    <Grid>
                        {formValue.mechanic_type === 'MECHANIC' ? <Grid.Col span={6}>
                            <TextInput required label="Company Name" placeholder="Enter here" value={formValue?.company_name || ""} onChange={handleCompanyNameChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter coupon code</Text>}
                        </Grid.Col> : <Grid.Col span={6}>
                            <TextInput required label="Legal Name" placeholder="Enter here" value={formValue?.legal_name || ""} onChange={handleLegalNameChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter coupon code</Text>}
                        </Grid.Col>
                        }

                        {/* <Grid.Col span={6}>
                            <TextInput required type="email" label="Email" placeholder="Enter here" value={formValue?.email || ""} onChange={handleEmailChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter email</Text>}
                        </Grid.Col> */}
                        <Grid.Col span={6}>
                            <TextInput required type="number" label="Phone" placeholder="Enter here" value={formValue?.phone || ""} onChange={handlePhoneChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter phone</Text>}
                        </Grid.Col>
                        {formValue.mechanic_type === 'MECHANIC' ? <Grid.Col span={6}>
                            <TextInput required label="SSM Number" placeholder="Enter here" value={formValue?.ssm || ""} onChange={handleSMChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter SSM Number</Text>}
                        </Grid.Col> : <Grid.Col span={6}>
                            <TextInput required label="IC Number" placeholder="Enter here" value={formValue?.ic_number || ""} onChange={handleICChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter IC Number</Text>}
                        </Grid.Col>
                        }
                        <Grid.Col span={6}>
                            <Text for="services">Services</Text>
                            <Select
                                isMulti
                                name="Services"
                                placeholder='Services'
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={services}
                                options={bookingServices}
                                onChange={setServices}
                            />
                            {/* {services.length ? <Select
                                isMulti
                                name="Services"
                                placeholder='Services'
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={services}
                                options={bookingServices}
                                onChange={setServices}
                            />
                                : <Select
                                    isMulti
                                    name="Services"
                                    placeholder='Services'
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    // defaultValue={services}
                                    options={bookingServices}
                                    onChange={setServices}
                                />
                            } */}
                            {inputError && <Text style={{ color: '#EE7297' }}>Please select Services </Text>}
                        </Grid.Col>


                        <Grid.Col span={6}>
                            <TextInput required type="date" label="Subscription Start Date" placeholder="Pick date" withAsterisk value={formValue?.subscription_start_date || ""} onChange={handleDateChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please select start date </Text>}
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput required type="date" label="Subscription End Date" placeholder="Pick date" withAsterisk value={formValue?.subscription_end_date || ""} onChange={handleSubDateChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please select expiry date  </Text>}
                        </Grid.Col>
                        {formValue?.mechanic_type === 'FREELANCER' ? <Grid.Col span={12}>
                            <Textarea required label="Experience Summaryjj" placeholder="Enter here" row="10" value={formValue?.experience_summary || ""} onChange={handleDiscriptionChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter description </Text>}
                        </Grid.Col> : ''}
                        <Grid.Col span={6}>
                            <div style={{ marginTop: 0 }}>
                                <TextInput label="Select Photo" type="file" name="file" accept="image/png, image/jpg, image/jpeg" onChange={changeHandler} />
                            </div>

                        </Grid.Col>
                        <Grid.Col span={6}>
                            {
                                showProfileImage ?
                                    <div style={{ marginTop: 15 }}>
                                        <Image src={showProfileImage}
                                            alt="Profile Picture"
                                            sx={(_) => ({
                                                height: '80px',
                                                width: '80px',
                                                overflow: 'hidden',
                                            })} />
                                    </div>
                                    : <div style={{ marginTop: 15 }}>

                                        <Image src={formValue?.profile_pic}
                                            alt="Profile Picture"
                                            sx={(_) => ({
                                                height: '80px',
                                                width: '80px',
                                                overflow: 'hidden',
                                            })} />
                                    </div>
                            }
                        </Grid.Col>

                        {/* Ic Image */}
                        <Grid.Col span={12}>
                            <div className="mb-1">
                                <div style={{ marginTop: 0 }}>
                                    <TextInput
                                        label="Upload IC File"
                                        type="file"
                                        name="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        multiple
                                        onChange={handleFileSelect}
                                    />
                                </div>
                            </div>
                        </Grid.Col>


                        <Grid.Col span={12}>
                            {files.map((file, index) => (
                                <Box
                                    key={file.name || file.preview} // Use file name or preview URL as a key
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #ccc',
                                        padding: '0.5rem',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {file.type === 'application/pdf' || file.type === 'pdf' ? (
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <FaFilePdf size={40} color="red" />
                                            <span style={{ marginLeft: '10px' }}>{file.name}</span>
                                        </Box>
                                    ) : (
                                        <Image src={file.path || file.preview} alt={file.name} width={50} height={50} />
                                    )}
                                    <Button
                                        color="red"
                                        size="xs"
                                        onClick={() => handleDeselectFile(index)}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            ))}
                        </Grid.Col>

                        {/* SSM Document */}
                        <Grid.Col span={12}>
                            <div className="mb-1">
                                <div style={{ marginTop: 0 }}>
                                    <TextInput
                                        label="Upload SSM File"
                                        type="file"
                                        name="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        multiple
                                        onChange={handleFileSelectForSSM}
                                    />
                                </div>
                            </div>
                        </Grid.Col>


                        <Grid.Col span={12}>
                            {ssmDocuments.map((file, index) => (
                                <Box
                                    key={file.name || file.preview} // Use file name or preview URL as a key
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #ccc',
                                        padding: '0.5rem',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {file.type === 'application/pdf' || file.type === 'pdf' ? (
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <FaFilePdf size={40} color="red" />
                                            <span style={{ marginLeft: '10px' }}>{file.name}</span>
                                        </Box>
                                    ) : (
                                        <Image src={file.path || file.preview} alt={file.name} width={50} height={50} />
                                    )}
                                    <Button
                                        color="red"
                                        size="xs"
                                        onClick={() => handleDeselectFileForSSM(index)}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            ))}
                        </Grid.Col>

                        {/* Driving Document */}
                        <Grid.Col span={12}>
                            <div className="mb-1">
                                <div style={{ marginTop: 0 }}>
                                    <TextInput
                                        label="Upload Driving License File"
                                        type="file"
                                        name="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        multiple
                                        onChange={handleFileSelectForDriving}
                                    />
                                </div>
                            </div>
                        </Grid.Col>


                        <Grid.Col span={12}>
                            {drivingLicense.map((file, index) => (
                                <Box
                                    key={file.name || file.preview} // Use file name or preview URL as a key
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #ccc',
                                        padding: '0.5rem',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {file.type === 'application/pdf' || file.type === 'pdf' ? (
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <FaFilePdf size={40} color="red" />
                                            <span style={{ marginLeft: '10px' }}>{file.name}</span>
                                        </Box>
                                    ) : (
                                        <Image src={file.path || file.preview} alt={file.name} width={50} height={50} />
                                    )}
                                    <Button
                                        color="red"
                                        size="xs"
                                        onClick={() => handleDeselectFileForDriving(index)}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            ))}
                        </Grid.Col>

                         {/* Utlity Bill */}
                         <Grid.Col span={12}>
                            <div className="mb-1">
                                <div style={{ marginTop: 0 }}>
                                    <TextInput
                                        label="Current Utility Bill"
                                        type="file"
                                        name="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        multiple
                                        onChange={handleFileSelectForUtlity}
                                    />
                                </div>
                            </div>
                        </Grid.Col>


                        <Grid.Col span={12}>
                            {utilityBill.map((file, index) => (
                                <Box
                                    key={file.name || file.preview} // Use file name or preview URL as a key
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #ccc',
                                        padding: '0.5rem',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {file.type === 'application/pdf' || file.type === 'pdf' ? (
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <FaFilePdf size={40} color="red" />
                                            <span style={{ marginLeft: '10px' }}>{file.name}</span>
                                        </Box>
                                    ) : (
                                        <Image src={file.path || file.preview} alt={file.name} width={50} height={50} />
                                    )}
                                    <Button
                                        color="red"
                                        size="xs"
                                        onClick={() => handleDeselectFileForUtlity(index)}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            ))}
                        </Grid.Col>

                        {/* Qualification Certificate */}

                        <Grid.Col span={12}>
                            <div className="mb-1">
                                <div style={{ marginTop: 0 }}>
                                    <TextInput
                                        label="Upload Qualification Certificate"
                                        type="file"
                                        name="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        multiple
                                        onChange={handleFileSelectForCertificate}
                                    />
                                </div>
                            </div>
                        </Grid.Col>


                        <Grid.Col span={12}>
                            {qualificationCertificate.map((file, index) => (
                                <Box
                                    key={file.name || file.preview} // Use file name or preview URL as a key
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #ccc',
                                        padding: '0.5rem',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {file.type === 'application/pdf' || file.type === 'pdf' ? (
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <FaFilePdf size={40} color="red" />
                                            <span style={{ marginLeft: '10px' }}>{file.name}</span>
                                        </Box>
                                    ) : (
                                        <Image src={file.path || file.preview} alt={file.name} width={50} height={50} />
                                    )}
                                    <Button
                                        color="red"
                                        size="xs"
                                        onClick={() => handleDeselectFileForCertificte(index)}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            ))}
                        </Grid.Col>

                         {/* Libality Insurance */}
                         <Grid.Col span={12}>
                            <div className="mb-1">
                                <div style={{ marginTop: 0 }}>
                                    <TextInput
                                        label="Upload Liability Insurance"
                                        type="file"
                                        name="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        multiple
                                        onChange={handleFileSelectForLibality}
                                    />
                                </div>
                            </div>
                        </Grid.Col>


                        <Grid.Col span={12}>
                            {liabilityInsurance.map((file, index) => (
                                <Box
                                    key={file.name || file.preview} // Use file name or preview URL as a key
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #ccc',
                                        padding: '0.5rem',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {file.type === 'application/pdf' || file.type === 'pdf' ? (
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <FaFilePdf size={40} color="red" />
                                            <span style={{ marginLeft: '10px' }}>{file.name}</span>
                                        </Box>
                                    ) : (
                                        <Image src={file.path || file.preview} alt={file.name} width={50} height={50} />
                                    )}
                                    <Button
                                        color="red"
                                        size="xs"
                                        onClick={() => handleDeselectFileForLibality(index)}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            ))}
                        </Grid.Col>


                        <Grid.Col span={12}>
                            <Group position="left" mt="md">
                                <Button color="dark" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button color="yellow" type="submit">Save</Button>
                            </Group>
                        </Grid.Col>

                    </Grid>
                </form>


            </Card>
        </Box >
    );
};

export default Update;
