import React, { useState, useEffect } from 'react';
import { Box, Avatar, Image, Badge, Switch, Tooltip } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { FiEdit, FiTrash, FiEye, FiMoreVertical } from "react-icons/fi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import moment from 'moment';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';


// Delete and Update
import DeleteUpdate from './DeleteUpdate';
import Footer from '../../../../components/Footers/Footer';
import TableLoadingSkeleton from '../../../../components/TableSkelton';

// API-Service
import { mechanicListService, mechanicProfileUpdateStatusService, mechanicUpdateStatusService, mechanicDetail } from '../../../../service/mechanic';

// Redux-Settings
import {
  setMechanicList,
  setMechanicDetail,
  setTotalEntry,
  selectFilterTerm,
  selectTotalEntry,
  setFilteredList,
  selectFilteredList,
  selectMechanicList,
} from '../../../../redux/mechanicSlice';    

// Auth-Image
import AuthImage from '../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../assets/images/placeholderThumb.png';

const ListingTable = () => {
  const filterTag = useSelector(selectFilterTerm);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const Listing = useSelector(selectMechanicList);
  const [currentPage, setCurrentPage] = useState(0);
  const totalEntry = useSelector(selectTotalEntry);
  const [list, setList] = useState([]);
  const [listOther, setOther] = useState([]);
  const [otherName, setOtherServices] = useState([]);

  // console.log('otherName', otherName)

  const token = getToken();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const countPerPage = 10;

  // const setServices = () => {
  //   const newArray1 = []
  //   if (Listing.length > 0) {
  //     Listing.forEach((item) => {
  //       newArray1.push(item.services)
  //     })
  //     setList(newArray1)
  //     console.log('list', list)
  //     if (newArray1.length > 0) {
  //       newArray1.forEach((item) => {
  //         const newArray = []
  //         if (item.other_services) {
  //           console.log('Inner', item.other_services)
  //           newArray.push(item.other_services)
  //         }
  //         setOther(listOther)
  //       })
  //       console.log('listOther', listOther)
  //     }
  //   }
  //   setOtherServices(listOther)
  // }

  // const myOtherServices = (abc) => {
  //   console.log('abc', abc)
  //   const newArray = []
  //   if (abc.length > 0) {
  //     abc.forEach((item) => {
  //       console.log('Inner', item.other_services)
  //       if (item.other_services) {
  //         newArray.push(item.other_services)
  //       } else {
  //         newArray.push('NA')
  //       }
  //       // setOther(listOther)
  //     })
  //     console.log('listOther', newArray)
  //   }

  // }

  const fetchData = async (search, page) => {
    setLoading(true)
    const result = await mechanicListService({ search, page, countPerPage, token });
    
    if (result?.data?.status) {
      setLoading(false);
      dispatch(setMechanicList(result?.data?.data?.mechanics));
      dispatch(setFilteredList(result?.data?.data?.mechanics));
      dispatch(setTotalEntry(result?.data?.data?.pagination?.total));
    } else {
      setLoading(false);
      ErrorToast(result);
    }
  };

  const statusUpdate = async (rowRecord) => {
    const newStatus = rowRecord.is_subscribed === 1 ? 0 : 1

    const statusUpdateResult = await mechanicUpdateStatusService({ id: rowRecord.id, token, status: newStatus });
    if (statusUpdateResult?.data?.status) {
      fetchData(filterTag, pageCount);
      SuccessToast(statusUpdateResult?.data?.message)
    } else {
      ErrorToast(statusUpdateResult);
    }
  };

  const profileStatusUpdate = async (rowRecord) => {
    const profileStatusUpdateResult = await mechanicProfileUpdateStatusService({ id: rowRecord.id, token });
    if (profileStatusUpdateResult?.data?.status) {
      fetchData(filterTag, pageCount);
      SuccessToast(profileStatusUpdateResult?.data?.message)
    } else {
      ErrorToast(profileStatusUpdateResult);
    }
  };  

  useEffect(() => {
    setLoading(true);
    fetchData(filterTag, pageCount);
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [filterTag, pageCount]);

  const viewDetails = async (rowRecord) => {
    navigate(`/mechanic-detail/${rowRecord?.id}`)
  };

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id ? row.id : '--',
      minWidth: '40px'
    },
    // {
    //   name: 'Profile',
    //   selector: (row) => (
    //     row?.profile_pic ? (
    //       <Avatar
    //         radius={10}
    //         size={60}
    //         src={row?.profile_pic}
    //         alt="Profile"
    //         href={row?.profile_pic}
    //         target="_blank"
    //         component="a"
    //       />

    //     ) : ( 
    //       <Image
    //         src={PlaceHolderThumb}
    //         alt="Avatar Thumb"
    //         sx={(_) => ({
    //           height: '60px',
    //           width: '60px',
    //           overflow: 'hidden',
    //         })}
    //       />
    //     )),
    // },
    {
      name: 'Profile',
      selector: (row) => (
        (
          <Avatar
            radius={10}
            size={40}
            src={row?.profile_pic || PlaceHolderThumb}
            alt="Profile"
            href={row?.profile_pic}
            target="_blank"
            component="a"
            style={{margin : '5px 0'}}
          />

        )),
        minWidth: '90px'
      
    },
    {
      name: 'Mechanic Type',
      selector: (row) => row.mechanic_type ? row.mechanic_type : '--',
      minWidth: '108px'
    },
    {
      name: 'Company Name',
      selector: (row) => {
        let name = '';
        if (row.company_name === null) {
          name = '--'
        }
        else if (row.company_name) {
          name = row.company_name
        }
        else {
          name = '--'
        }
        return (name)
      },
      minWidth: '100px'
    },
    {
      name: 'Legal Name',
      selector: (row) => {
        let name = '';
        if (row.legal_name === null) {
          name ="NA"
        }
        else if (row.legal_name) {
          name = row.legal_name
        }
        else {
          name = '--'
        }
        return (name)
      },
      minWidth: '110px'
    },

    {
      name: 'Profile Status',
      selector: (row) =>
        <Switch
          onChange={() => profileStatusUpdate(row)}
          onLabel="Active"
          offLabel="In Active"
          size="md"
          checked={row.status === 1}
          color="yellow"
        />,
      minWidth: '90px',
    },
    {
      name: 'Subscription Status',
      selector: (row) => (
        row.subscription_expired === 1 ? <Switch
          onChange={() => statusUpdate(row)}
          onLabel="Active"
          offLabel="In Active"
          size="md"
          checked={row.subscription_expired === 0}
          color="green"
        /> : <Switch
          checked={row.is_subscribed === 1}
          onChange={() => statusUpdate(row)}
          onLabel="Active"
          offLabel="In Active"
          size="md"
          color="yellow"
        />
      ),
      minWidth: '90px',
    },
    {
      name: 'Subscription Expire Date',
      selector: (row) => (row.subscription_expired === 0 ? row.subscription_end_date ? moment(row.subscription_end_date).format("DD-MM-YYYY") : 'Not Subscribed' : 'Subcription Expired'),
      minWidth: '130px'
    },
    {
      name: 'Created Date',
      selector: (row) => moment(row.created_at).format("DD-MM-YYYY"),
      minWidth: '120px'
    },

    {
      name: 'View',
      selector: (row) => <FiEye style={{ cursor: 'pointer' }} size={22} tableRecord={row} onClick={() => viewDetails(row)} />,
      sortable: true,
      minWidth: '48px'
    },
    // {
    //   name: 'Other Service',
    //   // selector: (row) => 
    //   // <Badge color="green" size={14} >{myOtherServices(row.services)}</Badge>,
    //   // {
    //   //   if (listOther.length > 0) {
    //   //     listOther.map((item, index) => <Badge color="green" size={14} key={item.id}>{item}</Badge>)
    //   //   } else {
    //   //     <Badge color="green" size={14}>NA</Badge>
    //   //   }
    //   // },
    //   selector: (row) => row?.services?.length && row?.services.map((item, index) => item.other_services ? <Badge color="green" size={14} key={item.id}>{item.other_services}</Badge> : ''),
    //   minWidth: '200px',
    //   sortable: true,
    // },
    {
      name: 'Action',
      selector: (row) => <DeleteUpdate tableRecord={row} />,
      sortable: true,
      minWidth: '50px'
    },
  ];

   // ** Function to handle Pagination
   const handlePagination = page => {
    setCurrentPage(page.selected)
}

   // ** Custom Pagination
   const CustomPagination = () => (
    <ReactPaginate
        nextLabel={<div className='d-inline-flex align-items-center bg-white p-50 border border-2 rounded p-1'>
            <span className='fs-14 fw-bold me-2 text-dark '>Next</span>
            <AiOutlineArrowRight className='text-secondary' size={18} />
        </div>}
        breakLabel='...'
        previousLabel={<div className='d-inline-flex align-items-center bg-white p-50  border border-2 rounded p-1'>
            <AiOutlineArrowLeft className='text-secondary me-2' size={18} />
            <span className='fs-14 fw-bold text-dark' >Previous</span>
        </div>}
        pageRangeDisplayed={3}
        forcePage={currentPage}
        marginPagesDisplayed={3}
        activeClassName='active bg-light border rounded px-2 d-flex align-items-center text-dark border-3'
        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
        breakClassName='page-item me-3 d-flex align-items-center'
        nextClassName='page-item next-item flex-grow-1 text-end'
        previousClassName='page-item prev-item flex-grow-1 me-3'
        disabledLinkClassName='btn disabled p-0 border-0'
        pageCount={Math.ceil(totalEntry / 10) || 1}
        onPageChange={page => handlePagination(page)}
        containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-2 ps-1'
    />
)

  // if (loading) {
  //   return (
  //     <Box
  //     sx={() => ({
  //       margin: '0 auto',
  //       minHeight: 440,
  //       display: 'flex',
  //       justifyContent: 'center',
  //       alignItems: 'center',
  //       height: '100vh',
  //     })}
  //   >
  //     <span className="visually-hidden"><Loading /></span>
  //   </Box>
  //   );
  // }

  return (
    <Box
      sx={() => ({
        minHeight: '100vh',
        '.rdt_TableCol_Sortable div:first-of-type': {
          whiteSpace: 'initial',
          textOverflow: 'initial',
          overflow: 'visible'
        },
        '.rdt_TableCell div:first-of-type': {
          whiteSpace: 'initial',
          padding: '5px 0'
        },
        '@media (max-width: 820px)': {
          overflow: 'scroll',
        },
      })}
    >
      <Box className="rui-filemanager-content" >
        <Box className="table-responsive-lg mb-20 inner-content-fix"> 
          {loading ? <TableLoadingSkeleton/> : (
          <DataTable
            className="rui-datatable rui-filemanager-table table mb-20 mt-10"
            columns={columns}
            data={Listing}
            key={Listing?.id}
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationTotalRows={totalEntry}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{ noRowsPerPage: true }}
            onChangePage={(page) => setPageCount(page)}
            paginationComponent={CustomPagination}
            paginationDefaultPage={currentPage + 1} 
          />
          )}
         
        </Box>
      </Box>
    </Box>
  );
};

export default ListingTable;
