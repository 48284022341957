import React, { useState, useEffect } from 'react';
import { Box, Card, Avatar, Image, Switch, Badge, Tooltip, Menu, Button, Textarea, Modal, Group, TextInput, ActionIcon } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { FiEdit, FiTrash, FiXCircle, FiEye, FiMoreVertical, } from "react-icons/fi";
import moment from 'moment';
import { useForm } from '@mantine/form';
import ReactPaginate from 'react-paginate';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

import Loading from '../../../../../components/Loading/Loading';

// Utility-Service
import { getToken, removeUserSession } from '../../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../../utils/utilities';

import TableLoadingSkeleton from '../../../../../components/TableSkelton';


// API-Service
import { dataListingService, UpdateStatusService, bookingFilterService, cancelBookingService } from '../../../../../service/bookingService';

// Redux-Settings
import {
  selectStateData,
  setDataList,
  setTotalEntry,
  selectFilterTerm,
  selectTotalEntry,
  setFilteredList,
  selectFilteredList,
  setFilterTerm,
} from '../../../../../redux/bookingSlice';

// Auth-Image
import AuthImage from '../../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../../assets/images/placeholderThumb.png';

const ListingTable = ({id}) => {
  const filterTag = useSelector(selectFilterTerm);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const Listing = useSelector(selectFilteredList);
  const Listing = useSelector(selectStateData);
  const [currentPage, setCurrentPage] = useState(0);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const totalEntry = useSelector(selectTotalEntry);
  const [bookingId, setBookingId] = useState('');

  const token = getToken();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const countPerPage = 10;

  const form = useForm({
    initialValues: {
      cancel_reason: '',
    },
    validate: {
      cancel_reason: (value) => (value.length < 2 ? 'Please enter cancel reason' : null),
    },
  });

  const fetchData = async (filterTerm, page) => {
    setLoading(true)
    const result = await dataListingService({ filterTerm, page, countPerPage, token, id });
    if (result?.data.status) {
      setLoading(false);
      dispatch(setDataList(result?.data?.data?.breakdown));
      // dispatch(setFilteredList(result?.data?.data?.breakdown));
      dispatch(setTotalEntry(result?.data?.data?.pagination?.total));
      dispatch(setFilterTerm(""))

    } else {
      setLoading(false);
      dispatch(setFilterTerm(""))
      ErrorToast(result);
    }
  };


  useEffect(() => {
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);

  const viewDetails = async (rowRecord) => {
    navigate(`/admin/booking-detail/${rowRecord?.id}`)
  };

  const statusUpdate = async (rowRecord) => {
    const statusUpdateresult = await UpdateStatusService({ id: rowRecord.id, token });
    if (statusUpdateresult?.data?.status) {
      fetchData(filterTag, pageCount);
    } else {
      ErrorToast(statusUpdateresult);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData(filterTag, pageCount);
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [pageCount]);

  const hendleCancelModalOpen = (row) => {
    setIsUpdateModal(true);
    setBookingId(row.id);
  };

  const handleCancelBooking = async (values) => {
    if (bookingId) {
      const formData = new FormData()
      formData.append('booking_id', bookingId)
      formData.append('cancel_reason', values?.cancel_reason)
      const result = await cancelBookingService({ token, }, formData);
      if (result?.data?.status) {
        SuccessToast('Booking canceled successfully')
        setIsUpdateModal(false);
        fetchData();
        form.reset();
      }
    }
  };

  const hendleCancel = () => {
    setIsUpdateModal(false);
  };

  
   // ** Function to handle Pagination
   const handlePagination = page => {
    setCurrentPage(page.selected)
}

   // ** Custom Pagination
   const CustomPagination = () => (
    <ReactPaginate
        nextLabel={<div className='d-inline-flex align-items-center bg-white p-50 border border-2 rounded p-1'>
            <span className='fs-14 fw-bold me-2 text-dark '>Next</span>
            <AiOutlineArrowRight className='text-secondary' size={18} />
        </div>}
        breakLabel='...'
        previousLabel={<div className='d-inline-flex align-items-center bg-white p-50  border border-2 rounded p-1'>
            <AiOutlineArrowLeft className='text-secondary me-2' size={18} />
            <span className='fs-14 fw-bold text-dark' >Previous</span>
        </div>}
        pageRangeDisplayed={3}
        forcePage={currentPage}
        marginPagesDisplayed={3}
        activeClassName='active bg-light border rounded px-2 d-flex align-items-center text-dark border-3'
        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
        breakClassName='page-item me-3 d-flex align-items-center'
        nextClassName='page-item next-item flex-grow-1 text-end'
        previousClassName='page-item prev-item flex-grow-1 me-3'
        disabledLinkClassName='btn disabled p-0 border-0'
        pageCount={Math.ceil(totalEntry / 10) || 1}
        onPageChange={page => handlePagination(page)}
        containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-2 ps-1'
    />
)


  const columns = [
    {
      name: 'Booking Code',
      selector: (row) => row.booking_code ? row.booking_code : '--',
      minWidth: '40px'
    },
    // {
    //   name: 'Profile',
    //   selector: (row) => (
    //     (
    //       <Avatar
    //         radius={10}
    //         size={60}
    //         src={row?.customer?.profile_image || PlaceHolderThumb}
    //         alt="Profile"
    //         href={row?.customer?.profile_image}
    //         target="_blank"
    //         component="a"
    //         style={{margin : '5px 0'}}
    //       />
    //     )),
    //     minWidth: '100px'
    // },
    {
      name: 'Customer Name',
      selector: (row) => row.customer?.name ? row.customer?.name : '-',
      minWidth: '85px',
      wrap: true

    },
    // {
    //   name: 'Customer Phone',
    //   selector: (row) => row.customer?.phone ? row.customer?.phone : '-',

    // },
    // {
    //   name: 'Booking Code',
    //   selector: (row) => row.booking_code ? row.booking_code : '-',
    //   minWidth: '100px'
    // },
    {
      name: 'Booking Current Status',
      // row.booking_current_status ? <Badge color="red">{row.booking_current_status}</Badge> : '-',
      selector: (row) => {
        let showData = ''
        if (row.booking_current_status === 'ONGOING') {
          showData = <Badge size={14} color="indigo">ONGOING</Badge>
        }
        else if (row.booking_current_status === 'TOWED') {
          showData = <Badge size={14} color="violet">TOWED</Badge>
        }
        else if (row.booking_current_status === 'COMPLETED') {
          showData = <Badge size={11} color="green">COMPLETED</Badge>
        }
        else if (row.booking_current_status === 'CANCELED') {
          showData = <Badge size={11} color="red">CANCELLED</Badge>
        }
        else if (row.booking_current_status === 'RELEASE_MECHANIC') {
          showData = <Badge size={11} color="orange">MECHANIC RELEASED</Badge>


        }
        return (showData)
      },
      wrap: true,
      minWidth: '150px'
    },
    {
      name: 'Booking Status',
      selector: (row) => row.booking_status ? row.booking_status : '-',
    },
    {
      name: 'Mechanic Accepted',
      selector: (row) => row.mechanic_accepted_details?.mechanic?.legal_name
        ? row.mechanic_accepted_details?.mechanic?.legal_name : row.mechanic_accepted_details?.mechanic?.company_name,
      minWidth: '100px',
      wrap: true
    },
    // {
    //   name: 'Mechanic Phone',
    //   selector: (row) => row.mechanic_accepted_details?.mechanic ? row.mechanic_accepted_details?.mechanic.phone : '-',
    //   minWidth: '150px',
    //   maxWidth: '250px'
    // },
    {
      name: 'Service Charge',
      selector: (row) => row.total_price ? `RM ${row.total_price}` : '-',
      minWidth: '85px'
    },
    {
      name: 'Coupon Discount',
      selector: (row) => row.discount_price ? `RM ${(row.total_price - row.discount_price).toFixed(2)}` : `RM ${row.total_price}`,
      minWidth: '70px',
      wrap: true
    },
    {
      name: 'Payable Amount',
      selector: (row) => row.discount_price ? `RM ${row.discount_price}` : '-',
      minWidth: '70px',
      wrap: true
    },
    {
      name: 'Payment Status',
      selector: (row) => {
        let showData = ''
        if (row.payment_status === 'NOTPROCESSED') {
          showData = <Badge size="md" color="red">UNPAID</Badge>
        }
        else if (row.payment_status === 'COMPLETED') {
          showData = <Badge size="md" color="green">PAID</Badge>
        }
        return (showData)
      },
      minWidth: '80px',
    },
    {
      name: 'Payment Type',
      selector: (row) => row.payment_type ? row.payment_type : '-',
    },
    // {
    //   name: 'Description Extra Payment',
    //   selector: (row) => row?.description ? row?.description : '-',
    // },
    // {
    //   name: 'Total Extra Payment Request',
    //   selector: (row) => row.extra_payment_amount ? `RM ${row.extra_payment_amount}` : '-',
    // },
    // {
    //   name: 'Extra Payment Status',
    //   selector: (row) => (
    //     <Switch
    //       checked={row.is_extra_payment === 1}
    //       onLabel="Completed"
    //       offLabel="InComplete"
    //       size="md"
    //       color="yellow"
    //     />
    //   ),
    //   minWidth: '150px',
    //   maxWidth: '250px'
    // },
   
    {
      name: 'Car Details',
      selector: (row) => <FiEye style={{ cursor: 'pointer' }} size={22} tableRecord={row} onClick={() => viewDetails(row)} />,
      sortable: true,
      minWidth: '30px'
    },
    // {
    //   name: 'Cancel Status',
    //   selector: (row) => (
    //     <Switch
    //       checked={row.is_canceled === 1}
    //       disabled
    //       onLabel="YES"
    //       offLabel="NO"
    //       size="md"
    //       color="yellow"
    //     />
    //   ),
    //   maxWidth: '150px',
    // },
    // {
    //   name: 'Booking Competed Remarks',
    //   selector: (row) =>
    //     (row.is_reported === 1 ? <BookingRemarks tableRecord={row} /> : ''),
    //   sortable: true,
    //   minWidth: '120px'
    // },
    // {
    //   name: 'Cancelled By',
    //   selector: (row) => row.canceled_by ? row.canceled_by : '-',
    //   minWidth: '150px',
    //   maxWidth: '250px'
    // },

    // {
    //   name: 'Action',
    //   selector: (row) => {
    //     let showData = ''
    //     if (row.booking_current_status === 'ONGOING') {
    //       showData = row.is_canceled === 1 ? <BookingDescription tableRecord={row} /> :
    //         <FiXCircle style={{ cursor: 'pointer', color: 'red' }} size={22} tableRecord={row} onClick={() => hendleCancelModalOpen(row)} />
    //     }
    //     else if (row.booking_current_status === 'TOWED') {
    //       showData = row.is_canceled === 1 ? <BookingDescription tableRecord={row} /> :
    //         <FiXCircle style={{ cursor: 'pointer', color: 'red' }} size={22} tableRecord={row} onClick={() => hendleCancelModalOpen(row)} />
    //     }
    //     else if (row.booking_current_status === 'CANCELED') {
    //       showData = row.is_canceled === 1 ? <BookingDescription tableRecord={row} /> :
    //         <FiXCircle style={{ cursor: 'pointer', color: 'red' }} size={22} tableRecord={row} onClick={() => hendleCancelModalOpen(row)} />
    //     }
    //     else if (row.booking_current_status === 'COMPLETED') {
    //       showData = <BookingRemarks tableRecord={row} />
    //       // showData = row.is_canceled === 1 ? <BookingRemarks tableRecord={row} /> : ''
    //       // <FiXCircle style={{ cursor: 'pointer', color: 'red' }} size={22} tableRecord={row} onClick={() => hendleCancelModalOpen(row)} />
    //     }
    //     else if (row.booking_current_status === 'RELEASE_MECHANIC') {
    //       showData = <BookingRemarks tableRecord={row} />
    //       // row.is_canceled === 1 ? <BookingRemarks tableRecord={row} /> : ''
    //       // <FiXCircle style={{ cursor: 'pointer', color: 'red' }} size={22} tableRecord={row} onClick={() => hendleCancelModalOpen(row)} />
    //     }
    //     return (showData)

    //   },
    //   sortable: true,
    //   minWidth: '100px'
    // },
  ];

 

  return (

     <Card>
     <Box
     sx={() => ({
       minHeight: 'auto',
       '.rdt_TableCol_Sortable div:first-of-type': {
         whiteSpace: 'initial',
         textOverflow: 'initial',
         overflow: 'visible'
       },
       '.rdt_TableCell div:first-of-type': {
         whiteSpace: 'initial',
         padding: '5px 0'
       },
       '@media (max-width: 820px)': {
         overflow: 'scroll',
       },
     })}
   >  
      {loading ? <TableLoadingSkeleton/> : (
      <DataTable
        className=""
        columns={columns}
        data={Listing}
        key={Listing?.id}
        highlightOnHover
        responsive
        pagination
        paginationServer
        paginationTotalRows={totalEntry}
        paginationPerPage={countPerPage}
        paginationComponentOptions={{ noRowsPerPage: true }}
        onChangePage={(page) => setPageCount(page)}
        paginationComponent={CustomPagination}
        paginationDefaultPage={currentPage + 1} 
      />
      )}
      </Box>

    </Card> 
  );
};

export default ListingTable;
