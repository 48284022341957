import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Card, Menu, Button, Image, Textarea, Modal, Group, Box, TextInput, Notification, ActionIcon } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconCheck, IconX } from '@tabler/icons';
import { FiEdit, FiTrash, FiEye, FiMoreVertical } from "react-icons/fi";


import BreakdownListing from './breakDownListing'
import ServiceListing from './serviceListing'


const HistoryTab = ({id}) => {
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const navigate = useNavigate();
   

    return (
        <>
            <Card>
                <h5>Breakdown History</h5>
                <Tabs color="teal" defaultValue="first">
                <Tab label="Breakdown">
                <BreakdownListing id={id}/>
                </Tab>
                <Tab label="Service">
                <ServiceListing id={id}/>
                </Tab>
                </Tabs>
            </Card>
        </>
    );
};

export default HistoryTab
