import Swal from 'sweetalert2';
import axios from 'axios';

const url = process.env.REACT_APP_APP_API_ENDPOINT;

export const ErrorToast = (result, resultAll) => {
    Swal.fire({
        position: 'top-end',
        title: 'Error!',
        text: result?.data?.message || resultAll?.data?.message || 'Something went wrong',
        icon: 'error',
        showConfirmButton: false,
        timer: 4000
    });
} 

export const CatchErrorToast = (error) => {
    Swal.fire({
        position: 'top-end',
        title: 'Error!',
        text: error.response.data.message || 'Something went wrong',
        icon: 'error',
        showConfirmButton: false,
        timer: 4000
    });
}
  
export const SuccessToast = (message) => {
    Swal.fire({
        position: 'top-end',
        title: 'Success!',
        text: message,
        icon: 'success',
        showConfirmButton: false,
        timer: 4000
    });
}

export const CustomErrorToast = (error) => {
    Swal.fire({
        position: 'top-end',
        text: error,
        icon: 'error',
        showConfirmButton: false,
        timer: 4000
    });
}

export const handleDownload = async(imageUrl, token) => {
    const imageUri = `${url}/${imageUrl}`
        axios.get(imageUri,{
            responseType: 'arraybuffer',
            headers: {
                Authorization: `Bearer ${token}`
            }
    })
    .then(response => {
        const result = `data:${
          response.headers["content-type"]
        };base64,${Buffer.from(response.data, "binary").toString("base64")}`;

        const link = document.createElement("a");
        link.href = result;
        link.download = "image.png";
        link.click();

      })
      .catch(ex => 0);
}