import React from 'react';
import { Link, useLocation  } from "react-router-dom";
import { ThemeIcon, UnstyledButton, Group, Text } from '@mantine/core';
import { GitPullRequest, AlertCircle, Messages, Database } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import { FiUsers, FiBell, FiClipboard, FiFeather, FiMail, FiCalendar, FiUserCheck, FiSettings, FiHome, FiGift, FiDollarSign, FiCornerRightDown } from "react-icons/fi";

const  MainLink = ({ icon, color, label, pageLink }) => {
  const { pathname } = useLocation();
  return(
  <Link style={{textDecoration: 'none'}} to={pageLink}>
    <UnstyledButton 
        sx={(theme) => {
          if(theme.colorScheme !== 'dark'){
            return({
              display: 'block', 
              width: '100%',
              padding: theme.spacing.xs,
              borderRadius: theme.radius.sm,
              color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
              backgroundColor: pageLink === pathname ? theme.colors.gray[5] : '',
              '&:hover': {
                backgroundColor: theme.colors.gray[0]
              }
            }
          )} 
          return({
            display: 'block',
              width: '100%',
              padding: theme.spacing.xs,
              borderRadius: theme.radius.sm,
              color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
              backgroundColor: pageLink === pathname ? theme.colors.dark[6] : '',
              '&:hover': {
                backgroundColor: theme.colors.dark[6] ,
              }
          })
        }}
      >
        <Group>
          <ThemeIcon color={color} variant="light">
            {icon}
          </ThemeIcon>
          
            <Text sx={(theme) => ({
              color: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.black
            })} 
            
            size="sm">{label}</Text>
        </Group>
      </UnstyledButton>
    </Link>
  )};
 

MainLink.propTypes = {
    icon: PropTypes.element.isRequired,
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    pageLink: PropTypes.string.isRequired
}

const data = [ 
  { icon: <FiHome size={16} />, color: 'blue', label: 'Dashboard', pageLink: '/' },
  { icon: <FiUsers size={16} />, color: 'blue', label: 'Users List',  pageLink: '/admin/users' },
  { icon: <FiUserCheck size={16} />, color: 'teal', label: 'Mechanic List',  pageLink: '/admin/mechanics' },
  { icon: <FiSettings size={16} />, color: 'violet', label: 'Services List',  pageLink: '/admin/services' },
  { icon: <FiMail size={16} />, color: 'blue', label: 'Subscriptions',  pageLink: '/admin/plans' },
  { icon: <FiDollarSign size={16} />, color: 'teal', label: 'Banks',  pageLink: '/admin/banks' },
  { icon: <FiCalendar size={16} />, color: 'teal', label: 'Breakdown Bookings',  pageLink: '/admin/bookings' },
  { icon: <FiCalendar size={16} />, color: 'teal', label: 'Service Bookings',  pageLink: '/admin/service-bookings' },
  { icon: <AlertCircle size={16} />, color: 'teal', label: 'Top-ups',  pageLink: '/admin/topup' },
  // { icon: <FiDollarSign size={16} />, color: 'teal', label: 'Transactions',  pageLink: '/admin/transactions' },
  { icon: <FiCornerRightDown size={16} />, color: 'teal', label: 'Withdrawals Request',  pageLink: '/admin/withdrawals' },
  { icon: <FiGift size={16} />, color: 'teal', label: 'Coupons',  pageLink: '/admin/coupons' },
  { icon: <FiClipboard size={16} />, color: 'teal', label: 'Contact Us',  pageLink: '/admin/contacts' },
  // { icon: <FiClipboard size={16} />, color: 'teal', label: 'About',  pageLink: '/admin/about' },
  // { icon: <FiEdit2 size={16} />, color: 'teal', label: 'Terms & Conditions',  pageLink: '/admin/terms-conditions' },
  // { icon: <FiEdit2 size={16} />, color: 'teal', label: 'Privacy',  pageLink: '/admin/privacy' },
  { icon: <FiFeather size={16} />, color: 'teal', label: 'Complaints',  pageLink: '/admin/user-complains' },
  { icon: <FiBell size={16} />, color: 'teal', label: 'Notifications',  pageLink: '/admin/notifications' },
  { icon: <FiSettings size={16} />, color: 'teal', label: 'Strings',  pageLink: '/admin/string' },
  { icon: <FiSettings size={16} />, color: 'teal', label: 'App Setting',  pageLink: '/admin/customers' },
  { icon: <FiUsers size={16} />, color: 'blue', label: 'SOS Users',  pageLink: '/admin/sos-user' },

];

const NavItems = () => {
  const links = data.map((link) => <MainLink {...link} key={link.label} />);
  return <div>{links}</div>;
}

export default NavItems;