import React, { useState, useEffect } from 'react';
import { Box, Image, Switch } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

import Loading from '../../../../components/Loading/Loading';
import TableLoadingSkeleton from '../../../../components/TableSkelton';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Delete and Update
import DeleteUpdate from './DeleteUpdate';

// API-Service
import { dataListingService, UpdateStatusService, } from '../../../../service/notificationService';

// Redux-Settings
import {
  selectStateData,
  setDataList,
  setTotalEntry,
  selectFilterTerm,
  selectTotalEntry,
  setFilteredList,
  selectFilteredList,
} from '../../../../redux/notificationSlice';

// Auth-Image
import AuthImage from '../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../assets/images/placeholderThumb.png';


const ListingTable = () => {
  const filterTag = useSelector(selectFilterTerm);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const Listing = useSelector(selectFilteredList);
  const Listing = useSelector(selectStateData);
  const totalEntry = useSelector(selectTotalEntry);
  const [currentPage, setCurrentPage] = useState(0);

  const token = getToken();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const countPerPage = 10;


  const fetchData = async (filterTerm, page) => {
    const result = await dataListingService({ filterTerm, page, countPerPage, token });
    if (result?.data?.status) {
      setLoading(false);
      dispatch(setDataList(result?.data?.data?.notifications_list));
      dispatch(setTotalEntry(result?.data?.data?.pagination?.total));
    } else {
      setLoading(false);
      ErrorToast(result);
    }
  };

  const statusUpdate = async (rowRecord) => {
    const statusUpdateresult = await UpdateStatusService({ id: rowRecord.id, token });
    if (statusUpdateresult?.data?.status) {
      SuccessToast(statusUpdateresult.data.message)
      fetchData(filterTag, pageCount);
    } else {
      ErrorToast(statusUpdateresult);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData(filterTag, pageCount);
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [filterTag, pageCount]);

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id ? row.id : '--',
      width: '120px',
    },
    {
      name: 'User Type',
      selector: (row) => row.user_type === 2 ? 'Mechanic' : 'User',
      width: '120px',
    },
    {
      name: 'Title',
      selector: (row) => row.title ? row.title : '--',
      width: '190px',
    },

    {
      name: 'Message',
      selector: (row) => row.message ? row.message : '--',
      width: '380px',
    },

    {
      name: 'Created Date',
      selector: (row) => moment(row.created_at).format("DD-MM-YYYY"),
      minWidth: '120px',
    },
    // { 
    //   name: 'Actions',
    //   selector: (row) => <DeleteUpdate tableRecord={row} />,
    //   sortable: true, 
    //   minWidth: '120px'
    // },
  ];

   // ** Function to handle Pagination
   const handlePagination = page => {
    setCurrentPage(page.selected)
   }

   // ** Custom Pagination
   const CustomPagination = () => (
    <ReactPaginate
        nextLabel={<div className='d-inline-flex align-items-center bg-white p-50 border border-2 rounded p-1'>
            <span className='fs-14 fw-bold me-2 text-dark '>Next</span>
            <AiOutlineArrowRight className='text-secondary' size={18} />
        </div>}
        breakLabel='...'
        previousLabel={<div className='d-inline-flex align-items-center bg-white p-50  border border-2 rounded p-1'>
            <AiOutlineArrowLeft className='text-secondary me-2' size={18} />
            <span className='fs-14 fw-bold text-dark' >Previous</span>
        </div>}
        pageRangeDisplayed={3}
        forcePage={currentPage}
        marginPagesDisplayed={3}
        activeClassName='active bg-light border rounded px-2 d-flex align-items-center text-dark border-3'
        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
        breakClassName='page-item me-3 d-flex align-items-center'
        nextClassName='page-item next-item flex-grow-1 text-end'
        previousClassName='page-item prev-item flex-grow-1 me-3'
        disabledLinkClassName='btn disabled p-0 border-0'
        pageCount={Math.ceil(totalEntry / 10) || 1}
        onPageChange={page => handlePagination(page)}
        containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-2 ps-1'
    />
)

  if (loading) {
    return (
     
        <TableLoadingSkeleton />
      
    );
  }

  return (
    <Box>
      <Box className="rui-filemanager-content">
        <Box className="table-responsive-lg mb-20 inner-content-fix">
          <DataTable
            className="rui-datatable rui-filemanager-table table mb-20 mt-10"
            columns={columns}
            data={Listing}
            key={Listing?.id}
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationTotalRows={totalEntry}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{ noRowsPerPage: true }}
            onChangePage={(page) => setPageCount(page)}
            paginationComponent={CustomPagination}
            paginationDefaultPage={currentPage + 1} 
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ListingTable;
