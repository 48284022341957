import React, { useState, useEffect } from 'react';

import { Avatar, Divider, Box, Image, Switch, Card, Grid, Skeleton } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating'
import moment from 'moment';


import { FiLink, FiEdit, FiTrash, FiEye, FiMoreVertical, } from "react-icons/fi";

import Loading from '../../../../components/Loading/Loading';

// Utility-Service
import { getToken } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// API-Service
import { bookingDetail, UpdateStatusService } from '../../../../service/bookingService';

// Redux-Settings
import {
    setSingleData,
    selectStateData,
    selectStateSingleData
} from '../../../../redux/bookingSlice';
import myVoice from './my.mp3';

// Auth-Image
import AuthImage from '../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../assets/images/placeholderThumb.png';


const BookingDetail = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { id } = useParams()
    const [getBookingDetail, setBookingDetail] = useState(null)

    const token = getToken();

    const getSingleData = async () => {
        const result = await bookingDetail({ id, token });
        if (result?.data.status) {
            setLoading(false);
            setBookingDetail(result?.data.data)
        } else {
            setLoading(false);
            ErrorToast(result);
        }
    };

    useEffect(() => {
        getSingleData();
        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, []);

    const statusUpdate = async (item) => {
        const statusUpdateresult = await UpdateStatusService({ id: item.id, token });
        if (statusUpdateresult?.data?.status) {
            ErrorToast(statusUpdateresult?.data?.message);
            SuccessToast()
        } else {
            ErrorToast(statusUpdateresult);
        }
    };


    if (getBookingDetail?.booking_code) {
        return (
            <Card>
                <Grid>
                    <Grid.Col xs={12}>
                        <h3>Booking Detail</h3>
                    </Grid.Col>
                    {getBookingDetail.customer ? (
                        <>
                            <Grid.Col xs={1} >
                                <a href={getBookingDetail?.customer?.profile_image} target="_blank" rel="noopener noreferrer">
                                    <Avatar variant="outline" size="xl" color="violet" src={getBookingDetail?.customer?.profile_image} />
                                </a>
                            </Grid.Col>

                            <Grid.Col xs={2}>
                                <div>
                                    <h6>Name</h6>
                                    <Divider my="sm" />
                                    <p>{getBookingDetail?.customer.name ? getBookingDetail?.customer.name : '-'}</p>
                                </div>
                            </Grid.Col>
                            <Grid.Col xs={3} style={{ overflow: 'auto' }}>
                                <div>
                                    <h6>Email</h6>
                                    <Divider my="sm" />
                                    <p>{getBookingDetail?.customer?.email ? getBookingDetail?.customer?.email : '-'}</p>
                                </div>
                            </Grid.Col>

                            <Grid.Col xs={2}>
                                <div>
                                    <h6>Phone</h6>
                                    <Divider my="sm" />
                                    <p>{getBookingDetail?.customer?.phone ? getBookingDetail?.customer?.phone : '-'}</p>
                                </div>
                            </Grid.Col>
                        </>
                    ) : (
                        <Grid.Col xs={12}>
                            <p>Customer information not available</p>
                        </Grid.Col>
                    )}
                    <Grid.Col xs={2}>
                        <div>
                            <h6>Booking Code</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.booking_code ? getBookingDetail?.booking_code : '-'}</p>
                        </div>
                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <div>
                            <h6>Car Brand</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.car_brand ? getBookingDetail?.car_brand : '-'}</p>

                        </div>
                    </Grid.Col>
                    <Grid.Col xs={3}>
                        <div>
                            <h6>Car Model</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.car_model ? getBookingDetail?.car_model : '-'}</p>
                        </div>
                    </Grid.Col>

                    <Grid.Col xs={3}>
                        <div>
                            <h6>Car Year</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.car_year ? getBookingDetail?.car_year : '-'}</p>
                        </div>
                    </Grid.Col>

                    <Grid.Col xs={2}>
                        <div>
                            <h6>Fuel Type</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.fuel_type ? getBookingDetail?.fuel_type : '-'}</p>
                        </div>
                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <div>
                            <h6>Transmission</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.transmission ? getBookingDetail?.transmission : '-'}</p>
                        </div>
                    </Grid.Col>
                    
                    <Grid.Col span={2}>
                        <div>
                            <h6>Created Date</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.created_at ? moment(getBookingDetail.created_at).format("DD-MM-YYYY") : '-'}</p>
                        </div>
                    </Grid.Col>

                    <Grid.Col xs={6}>
                        <div>
                            <h6>Extra Payment Request</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.extra_payment.length > 0 ?
                                <div className='mechanicList'>
                                    {getBookingDetail?.extra_payment?.map(item => (
                                        <p> {item.description} || {`RM ${item.amount}`}</p>
                                    ))}
                                </div>
                                : '-'}
                        </div>
                    </Grid.Col>
                    <Grid.Col xs={6}>
                        <div>
                            <h6>Cancel By</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.canceled_by ? <p>{getBookingDetail?.canceled_by}</p> : <p>--</p>}

                        </div>
                    </Grid.Col>  

                    <Grid.Col xs={12}>
                        <div>
                            <h6>Cancel Reasons </h6>
                            <Divider my="sm" />
                            {getBookingDetail?.cancel_reason ? <p>{getBookingDetail?.cancel_reason}</p> : <p>No Remark</p>}

                        </div>
                    </Grid.Col>
                    <Grid.Col xs={12}>
                        <div>
                            <h6>User Complaint</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.report_reason ? <p>{getBookingDetail?.report_reason}</p> : <p>--</p>}

                        </div>
                    </Grid.Col>

                     <Grid.Col xs={12}>
                    
                                            <div>
                                                <h6 style={{ marginBottom: '0.5rem' }}>Damage Images</h6>
                                                <Divider my="sm" />
                                                {getBookingDetail && getBookingDetail?.damage_images && getBookingDetail?.damage_images.length > 0 ? (
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        {getBookingDetail?.damage_images.map(item => (
                                                            <a
                                                                key={item.name}
                                                                href={item.path}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                    
                                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                            >
                    
                                                                <Image
                                                                    src={item.path}
                                                                    alt="Damage Image"
                                                                    sx={(_) => ({
                                                                        height: '140px',
                                                                        width: '100px',
                                                                        border: '1px solid black',
                                                                        margin: '3px 5px',
                                                                        overflow: 'hidden',
                                                                    })}
                    
                                                                />
                    
                                                            </a>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <p>No Damage Image</p>
                                                )}
                                            </div>
                    
                                        </Grid.Col>
                    <Grid.Col xs={12}>
                        <div>
                            <h6>Problem Summary Voice</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.summary_voice ? (
                                <audio controls>
                                    <source src={getBookingDetail?.summary_voice} type="audio/mpeg" />
                                    <track src={getBookingDetail?.summary_voice} kind="captions" label="english_captions" />
                                    <track src={getBookingDetail?.summary_voice} kind="captions" label="spanish_captions" />
                                </audio>
                            ) : (
                                <p>No audio available</p>
                            )}
                        </div>
                    </Grid.Col>

                    <Grid.Col xs={12}>
                        <div>
                            <h6>Problem Summary</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.summary_text ? <p>{getBookingDetail?.summary_text}</p> : <p>--</p>}

                        </div>
                    </Grid.Col>
                    <Grid.Col xs={12}>
                        <div>
                            <h6>Job Location</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.current_location ? <p>{getBookingDetail?.current_location}</p> : <p>--</p>}

                        </div>
                    </Grid.Col>
                </Grid>
                {/* <Grid>
                    <Grid.Col xs={12}>
                        <div>
                            <p>Services</p>
                            <Divider my="sm" />
                            {userDetail?.services?.map(item => (
                                <Grid.Col xs={2}>
                                    <Box
                                        sx={(theme) => ({
                                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                                            textAlign: 'center',
                                            padding: theme.spacing.xl,
                                            borderRadius: theme.radius.md,
                                            cursor: 'pointer',

                                            '&:hover': {
                                                backgroundColor:
                                                    theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
                                            },
                                        })}
                                    >

                                        <p>{item.name}</p>
                                        <p>
                                            <Image
                                                src={item.image}
                                                alt="Avatar Thumb"
                                                sx={(_) => ({
                                                    height: '60px',
                                                    width: '60px',
                                                    overflow: 'hidden',
                                                })}
                                            />
                                        </p>

                                        <p><Switch
                                            checked={item.is_enabled}
                                            onChange={() => statusUpdate(item)}
                                            onLabel="ON"
                                            offLabel="OFF"
                                            size="md"
                                            color="yellow"
                                        /></p>




                                    </Box>
                                </Grid.Col>
                            ))}
                        </div>
                    </Grid.Col>
                </Grid> */}
            </Card >
        )

    }
    return (<>
      <Card>
                <Grid>
                    <Grid.Col span={12}>
                        <Skeleton height={30} width="60%" radius="sm" />
                    </Grid.Col>
                    {[...Array(6)].map((_, idx) => (
                        <Grid.Col  span={2}>
                            <Skeleton height={80} radius="sm" />
                        </Grid.Col>
                    ))}
                    <Grid.Col span={12}>
                        <Skeleton height={190} radius="sm" />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Skeleton height={190} radius="sm" />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Skeleton height={150} radius="sm" />
                    </Grid.Col>
                </Grid>
            </Card>
    </>)


};

export default BookingDetail;
