import React, { useState, useCallback } from 'react';
import { TextInput, Textarea, Select, MultiSelect, Card, Grid, Calendar, Box, Button, Modal, Group, Notification } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IconCheck, IconX } from '@tabler/icons';

import Loading from '../../../../components/Loading/Loading';

// API-Service   
import { dataListingService, CreateDataService } from '../../../../service/notificationService';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Redux-Settings
import { setDataList, setTotalEntry, setFilteredList } from '../../../../redux/notificationSlice';

const Create = () => {
  const [startDate, setStartDate] = useState(new Date());

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [couponType, setSelected] = useState(null);
  const [selectedDays, setDays] = useState([]);
  const token = getToken();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      title: '',
      user_type: null,
      message: '',
    },
    validate: {
      title: (value) => (value.length < 2 ? 'Please enter title' : null),
      // user_type: (value) => (value.length < 0 ? 'Please select type' : null),
      message: (value) => (value.length < 1 ? 'Please enter message' : null),
    },
  });


  const handleCreate = async (values) => {
    const removeTag = values?.message.replace(/(<([^>]+)>)/ig, '');
    const formData = new FormData()
    formData.append('title', values?.title)
    formData.append('user_type', values?.user_type)
    formData.append('message', removeTag)
    const result = await CreateDataService({ token, }, formData);

    if (result?.data?.status) {
      const resultAll = await dataListingService({ filterTerm: '', page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        form.reset();
        dispatch(setDataList(resultAll?.data.data?.coupon_lists));
        dispatch(setFilteredList(resultAll?.data?.coupon_lists));
        dispatch(setTotalEntry(result?.data?.data?.pagination?.total));
        setLoading(false);
        SuccessToast(result?.data?.message)
        navigate('/admin/notifications');
        form.reset();
      } else {
        setLoading(false);
        ErrorToast(resultAll.data?.message);

      }
    }
  };
  const handleSelectType = (e) => {
    dispatch(setSelected(e))
  };

  const hendleCancel = () => {
    navigate('/admin/notifications');
  };
  if (loading) {
    return (
      <Box>
        <Loading />
      </Box>
    );
  }


  return (
    <Box>
      <Card>
        <Grid>
          <Grid.Col span={12}><h3>Create Notification</h3></Grid.Col>
        </Grid>

        <form onSubmit={form.onSubmit((values) => handleCreate(values))}>
          <Grid>

            <Grid.Col span={6}>
              <Select
                label="User Type"
                placeholder="Pick one"
                required
                {...form.getInputProps('user_type')}
                data={[
                  { value: 1, label: 'User' },
                  { value: 2, label: 'Mechanic' },
                ]}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="Title" placeholder="Enter here" {...form.getInputProps('title')} />
            </Grid.Col>

            <Grid.Col span={12}>
              <TextInput label="Message" placeholder="Enter here" {...form.getInputProps('message')} />
            </Grid.Col>

            {/* <Grid.Col span={12}>
              <h3>Message</h3>
              <RichTextEditor style={{ minHeight: 300 }} label="Message" placeholder="Enter write here" {...form.getInputProps('message')} id="rte" />
            </Grid.Col> */}

            <Grid.Col span={12}>
              <Group position="left" mt="md">
                <Button color="dark" onClick={hendleCancel}>
                  Cancel
                </Button>
                <Button color="yellow" type="submit">Create</Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>


      </Card>
    </Box>
  );
};

export default Create;
