import React from 'react'

const RichEditor = () => (
  <div>
    Text Editor
  </div>
);


export default RichEditor
